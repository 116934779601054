import { Guid } from 'guid-typescript'
import _ from 'lodash'
import { PageModel } from './PageModel'

export interface IReportModel {
  id: string
}

type ValueOrFactory<T> = T | (() => T)
type PartialValueOrFactory<T> = {
  [P in keyof T]?: ValueOrFactory<T[P]>
}

export class ReportModel implements IReportModel {
  private _name: ValueOrFactory<string>
  private _description: ValueOrFactory<string>
  private _pages: ValueOrFactory<PageModel[]>

  get id(): string {
    return Guid.create().toString()
  }

  get name(): string {
    return this.getValue(this._name)
  }

  get description(): string {
    return this.getValue(this._description)
  }

  get pages(): PageModel[] {
    return this.getValue(this._pages)
  }

  constructor(init?: PartialValueOrFactory<ReportModel>) {
    ;(Object as any).assign(
      this,
      _.mapKeys(init, (_value: any, key: string) => '_' + key)
    )
  }

  private getValue<T>(value: ValueOrFactory<T>): T {
    if (value instanceof Function) {
      return value()
    }

    return value
  }
}
