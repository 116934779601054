import Axios from 'axios'
import LoginRequestModel from '../model/LoginRequestModel'
import LoginResponseModel from '../model/LoginResponseModel'
import { ApiUrl } from '../util/api.url'

export class AuthenticationService {
  private static defaultsAxios = Axios.create()
  private static baseUrl = ApiUrl.webapiurl() + '/Authentication/'

  static async login(request: LoginRequestModel): Promise<LoginResponseModel> {
    const response = await this.defaultsAxios
      .post(this.baseUrl + 'Login', request)
      .then((resp) => {
        return resp
      })
      .catch(function (error) {
        throw error
      })
    return response.data
  }
}
