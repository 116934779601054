


















import { Component, Vue } from 'nuxt-property-decorator'
import { getModule } from 'vuex-module-decorators'
import ReportModule from '~/store/ReportModule'

@Component
export default class MobileHeader extends Vue {
  /* Data */
  reportStore: ReportModule = getModule(ReportModule)

  /* Computed */
  get showFilterButton(): boolean {
    return this.reportStore.getShowFilter
  }
}
