



import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { getModule } from 'vuex-module-decorators'
import ReportModule from '~/store/ReportModule'

export type UserInfo = { name: string; email: string }

@Component({
  components: {},
})
export default class LogoOnlyHeader extends Vue {
  /* Props */
  reportStore: ReportModule = getModule(ReportModule)
  /* Events/Listeners */

  get headerTekst(): string {
    if (this.reportStore.getSelectedReportName === 'SITA_Comext') {
      return 'Selected dashboard: Comext'
    } else {
      return 'Selected dashboard: Comtrade'
    }
  }
}
