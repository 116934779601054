

























import { Component, Vue } from 'nuxt-property-decorator'
import SmallMenu from '@template/components/SmallMenu.vue'
import { IMenuItem, MenuItem } from '@template/classes/menu'
import { getModule } from 'vuex-module-decorators'
import DefaultMenuUser from '@template/components/defaultMenuUser.vue'
import { Report } from 'powerbi-client'
import defaultAppTemplate from './defaultAppTemplate.vue'
import LoginModule from '~/store/LoginModule'
import ReportModule from '~/store/ReportModule'
import FilterPanel from '~/components/FilterPanel.vue'
import MobileLayout from '~/components/mobile/MobileLayout.vue'
import PowerBiModule from '~/store/PowerBiModule'

export type UserInfo = { name: string; email: string }

const isDesktopQuery = window.matchMedia('(min-width: 1024px)')

@Component({
  components: {
    DefaultMenuUser,
    defaultAppTemplate,
    FilterPanel,
    MobileLayout,
    SmallMenu,
  },
})
export default class Default extends Vue {
  /* Props */
  public report: Report = {} as Report
  /* Watch */

  /* Data */
  isDesktop: boolean = isDesktopQuery.matches
  loginStore: LoginModule = getModule(LoginModule)
  reportStore: ReportModule = getModule(ReportModule)
  powerbiStore: PowerBiModule = getModule(PowerBiModule)

  menuItems: IMenuItem[] = []

  /* Enums */

  /* Lifecycle */
  async created() {
    await this.reportStore.getReportsAndPages()
    this.reportStore.SetMenuItems()
    const reports = this.reportStore.reports

    this.handleViewportChange()
    this.addEventListeners()
    this.menuItems = this.reportStore.getMenuItems
  }

  beforeDestroy() {
    this.removeEventListeners()
  }

  /* Computed */

  /* Methods */
  addEventListeners() {
    isDesktopQuery.addEventListener('change', this.handleDesktopChange)
    addEventListener('resize', this.handleViewportChange)
    addEventListener('orientationchange', this.handleViewportChange)
  }

  removeEventListeners() {
    isDesktopQuery.removeEventListener('change', this.handleDesktopChange)
    removeEventListener('resize', this.handleViewportChange)
    removeEventListener('orientationchange', this.handleViewportChange)
  }

  handleDesktopChange(event: MediaQueryListEvent) {
    this.isDesktop = event.matches
  }

  handleViewportChange() {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight / 100}px`)
  }

  get isLoggedIn(): boolean {
    return !!this.loginStore.token.length
  }
}
