import { Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { Report } from 'powerbi-client'
import { MainStore } from '~/store/index'

@Module({ namespaced: true, name: 'PowerBiModule', dynamic: true, store: MainStore })
export default class PowerBiModule extends VuexModule {
  pbiReport: Report = {} as Report
  pbiComtradeReport: Report = {} as Report

  get getComextPBIReport(): Report {
    return this.pbiReport
  }

  get getComtradePBIReport(): Report {
    return this.pbiComtradeReport
  }

  @Mutation
  setPowerbiReport(report: Report) {
    this.pbiReport = report
  }

  @Mutation
  setComtradePowerbiReport(report: Report) {
    this.pbiComtradeReport = report
  }
}
