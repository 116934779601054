


















import { Component, Ref, Vue } from 'nuxt-property-decorator'
import FilterPanel from '../FilterPanel.vue'
import MobileHeader from './MobileHeader.vue'

@Component({
  components: {
    FilterPanel,
    MobileHeader,
  },
})
export default class MobileLayout extends Vue {
  /* Data */
  showMenu: boolean = false

  /* Refs */
  @Ref()
  filters!: FilterPanel

  /* Event handlers */
  onToggleFilters() {
    this.filters.onSetShow()
  }

  onToggleMenu() {
    this.showMenu = !this.showMenu
  }
}
