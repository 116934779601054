import { Guid } from 'guid-typescript'
import _ from 'lodash'

export interface IHierarchyModel {
  id: string
}

type ValueOrFactory<T> = T | (() => T)
type PartialValueOrFactory<T> = {
  [P in keyof T]?: ValueOrFactory<T[P]>
}

export class HierarchyModel implements IHierarchyModel {
  private _value: ValueOrFactory<string>
  private _columnName: ValueOrFactory<string>
  private _anotherLevel: ValueOrFactory<boolean> // obsolete
  private _valueId: ValueOrFactory<number>
  private _levels: ValueOrFactory<HierarchyModel[]>

  get id(): string {
    return Guid.create().toString()
  }

  get value(): string {
    return this.getValue(this._value)
  }

  get columnName(): string {
    return this.getValue(this._columnName)
  }

  get anotherLevel(): boolean {
    // obsolete
    return this.getValue(this._anotherLevel)
  }

  get valueId(): number {
    return this.getValue(this._valueId)
  }

  get levels(): HierarchyModel[] {
    return this.getValue(this._levels)
  }

  constructor(init?: PartialValueOrFactory<HierarchyModel>) {
    ;(Object as any).assign(
      this,
      _.mapKeys(init, (_value: any, key: string) => '_' + key)
    )
  }

  private getValue<T>(value: ValueOrFactory<T>): T {
    if (value instanceof Function) {
      return value()
    }

    return value
  }
}
