import { render, staticRenderFns } from "./MobileLayout.vue?vue&type=template&id=6874b3d7&"
import script from "./MobileLayout.vue?vue&type=script&lang=ts&"
export * from "./MobileLayout.vue?vue&type=script&lang=ts&"
import style0 from "./MobileLayout.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MobileHeader: require('E:/Jenkins/workspace/PowerBi-Dashboard Sita (WEcR)/Presentatie/src/components/mobile/MobileHeader.vue').default,FilterPanel: require('E:/Jenkins/workspace/PowerBi-Dashboard Sita (WEcR)/Presentatie/src/components/FilterPanel.vue').default})


/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {VApp,VContainer,VMain,VNavigationDrawer})
