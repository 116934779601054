import Vuex, { Store } from 'vuex'
import LoaderModule from '@core/store/LoaderModule'
import Vue from 'vue'
import { config } from 'vuex-module-decorators'

Vue.use(Vuex)

Vue.config.errorHandler = (error) => {
  console.error(error)
}

// Set rawError to true by default on all @Action decorators
config.rawError = true

export const MainStore: Store<any> = new Vuex.Store<any>({
  modules: {
    loaderModule: LoaderModule,
  },
})

export default () => {
  return MainStore
}
