import { ApiUrl } from '../util/api.url'
import ReportResponseModel from '../model/ReportResponseModel'
import { BaseService } from './base.service'

export class ReportService {
  private static baseUrl = ApiUrl.webapiurl() + '/Report/'

  static async GetReports(): Promise<ReportResponseModel[]> {
    const response = await BaseService.getApiCall(this.baseUrl + 'GetReportsAndPages')
    return response.data
  }
}
