import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import LoginRequestModel from '~/shared/model/LoginRequestModel'
import LoginResponseModel from '~/shared/model/LoginResponseModel'
import { AuthenticationService } from '~/shared/service/authentication.service'
import { MainStore } from '~/store/index'

@Module({ namespaced: true, name: 'LoginModule', dynamic: true, store: MainStore })
export default class LoginModule extends VuexModule {
  token: string = localStorage.getItem('token') || ''

  @Mutation
  addUserInformationToStore(loginResponse: LoginResponseModel) {
    this.token = loginResponse.token
  }

  @Mutation
  setToken(token: string) {
    this.token = token
    localStorage.setItem('token', token)
  }

  @Mutation
  removeToken() {
    this.token = ''
    localStorage.removeItem('token')
  }

  @Action
  async login(request: LoginRequestModel): Promise<boolean> {
    let error = false
    await AuthenticationService.login(request)
      .then((resp) => {
        this.addUserInformationToStore(resp)
        this.setToken(resp.token)
        return (error = false)
      })
      .catch((error) => {
        localStorage.removeItem('token')
        throw error
      })
    return error
  }
}
