



































import { Component, Vue } from 'nuxt-property-decorator'
import { getModule } from 'vuex-module-decorators'
import { Report } from 'powerbi-client'
import FilterElement from './FilterElement.vue'
import ReportModule from '~/store/ReportModule'
import PowerBiModule from '~/store/PowerBiModule'

@Component({
  components: { FilterElement },
})
export default class FilterPanel extends Vue {
  public showPanel: boolean = false
  get getShowFilter(): boolean {
    return this.reportStore.getShowFilter
  }

  windowHeight: number = window.innerHeight

  get getWindowHeight(): number {
    const height = this.windowHeight - 225
    return height
  }

  get getFilters() {
    if (this.reportStore.selectedReportName === 'SITA_Comext') return this.reportStore.filters.filter((p) => p.reportId === 1)
    else return this.reportStore.filters.filter((p) => p.reportId === 2)
  }

  /* Data */
  reportStore: ReportModule = getModule(ReportModule)
  powerbiStore: PowerBiModule = getModule(PowerBiModule)

  async mounted() {
    this.reportStore.SetShowFilter(this.$router.currentRoute.path)
    window.addEventListener('resize', () => {
      this.windowHeight = window.innerHeight
    })

    await this.reportStore.getFilters(this.reportStore.selectedReportName)
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  }

  onResize() {
    this.windowHeight = window.innerHeight
  }

  onSetShow() {
    if (this.showPanel) {
      this.showPanel = false
    } else {
      this.showPanel = true
    }
  }

  onSetFilter() {
    let report = {} as Report
    const fiterList = [] as any[]

    if (this.reportStore.selectedReportName === 'SITA_Comtrade') {
      report = this.powerbiStore.getComtradePBIReport as Report
      const groupedByFilters = this.reportStore.comtradeActiveFilters.filter((p) => p.values.length > 0)
      groupedByFilters.forEach((comtradeActiveFilter) => {
        const values = comtradeActiveFilter.values.map(({ value }) => value)

        const filter = {
          $schema: 'http://powerbi.com/product/schema#basic',
          filterType: 1,
          target: {
            table: comtradeActiveFilter.table,
            column: comtradeActiveFilter.column,
          },
          operator: comtradeActiveFilter.operator,
          values: values,
        }
        fiterList.push(filter)
      })
      console.log(fiterList)
      report.setFilters(fiterList as any[])
    }

    if (this.reportStore.selectedReportName === 'SITA_Comext') {
      report = this.powerbiStore.getComextPBIReport as Report
      const groupedByFilters = this.reportStore.activeFilters.filter((p) => p.values.length > 0)
      groupedByFilters.forEach((activefilter) => {
        const values = activefilter.values.map(({ value }) => value)

        const filter = {
          $schema: 'http://powerbi.com/product/schema#basic',
          filterType: 1,
          target: {
            table: activefilter.table,
            column: activefilter.column,
          },
          operator: activefilter.operator,
          values: values,
        }
        fiterList.push(filter)
      })
      console.log(fiterList)
      report.setFilters(fiterList as any[])
    }
    this.showPanel = false
  }
}
