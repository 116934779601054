



































































import { Component, Vue, Prop, getModule } from 'nuxt-property-decorator'
import { DxTreeList, DxSelection, DxColumn, DxScrolling, DxFilterRow, DxPaging } from 'devextreme-vue/tree-list'
import DxRadioGroup from 'devextreme-vue/radio-group'
import { FilterModel } from '~/shared/model/FilterModel'
import ReportModule from '~/store/ReportModule'
import { ActiveFilterModel } from '~/shared/model/ActiveFilterModel'
import { HierarchyModel } from '~/shared/model/HierarchyModel'
import 'devextreme/dist/css/dx.common.css' // style sheet just for dev express
import 'devextreme/dist/css/dx.light.css' // style sheet just for dev express

@Component({
  components: { DxTreeList, DxSelection, DxColumn, DxScrolling, DxFilterRow, DxRadioGroup, DxPaging },
})
export default class FilterElement extends Vue {
  @Prop({
    type: Object as () => FilterModel,
    default: {} as FilterModel,
  })
  readonly filter!: FilterModel

  readonly values: HierarchyModel[] = this.filter.values
  readonly defaultSelection: string = this.filter.defaultValue
  public show: boolean = false
  reportStore: ReportModule = getModule(ReportModule)

  get getRadioButtonSelection() {
    if (this.filter.table === 'D_Declarants' && this.filter.reportId === 1) {
      const activeFilter = this.reportStore.activeFilters.filter((p) => p.table === this.filter.table)
      if (activeFilter.length > 0) {
        return activeFilter[0].values[0].value
      } else {
        return this.filter.defaultValue
      }
    }
    if (this.filter.table === 'D_Reporter' && this.filter.reportId === 2) {
      const activeFilter = this.reportStore.comtradeActiveFilters.filter((p) => p.table === this.filter.table)
      if (activeFilter.length > 0) {
        return activeFilter[0].values[0].value
      } else {
        return this.filter.defaultValue
      }
    }
    if (this.filter.table === 'D_Flow' && this.filter.reportId === 1) {
      const activeFilter = this.reportStore.activeFilters.filter((p) => p.table === this.filter.table)
      if (activeFilter.length > 0) {
        return activeFilter[0].values[0].value
      } else {
        return this.filter.defaultValue
      }
    }
    if (this.filter.table === 'D_Flow' && this.filter.reportId === 2) {
      const activeFilter = this.reportStore.comtradeActiveFilters.filter((p) => p.table === this.filter.table)
      if (activeFilter.length > 0) {
        return activeFilter[0].values[0].value
      } else {
        return this.filter.defaultValue
      }
    }
    if (this.filter.table === 'MetaData Parameters' && this.filter.reportId === 1) {
      const activeFilter = this.reportStore.activeFilters.filter((p) => p.table === this.filter.table)
      if (activeFilter.length > 0) {
        return activeFilter[0].values[0].value
      } else {
        return this.filter.defaultValue
      }
    }
    if (this.filter.table === 'MetaData Parameters' && this.filter.reportId === 2) {
      const activeFilter = this.reportStore.comtradeActiveFilters.filter((p) => p.table === this.filter.table)
      if (activeFilter.length > 0) {
        return activeFilter[0].values[0].value
      } else {
        return this.filter.defaultValue
      }
    }
  }

  get getSelectedRowKeys() {
    if (this.filter.name === 'Partners') {
      return this.reportStore.getSelectedPartnerIds
    }
    if (this.filter.name === 'EU not EU partner hierarchy') {
      return this.reportStore.getSelectedPartnerIdsComtrade
    }
    if (this.filter.name === 'Declarants') {
      return this.reportStore.getSelectedDeclarantIds
    }
    if (this.filter.name === 'Reporter') {
      return this.reportStore.getSelectedReportIds
    }
    if (this.filter.name === 'Products' && this.filter.reportId === 2) {
      return this.reportStore.getSelectedComtradeProductIds
    }
    if (this.filter.name === 'Products' && this.filter.reportId === 1) {
      return this.reportStore.getSelectedComextProductIds
    }
  }

  public SetSelectAllText(filterName: string) {
    if (filterName === 'Declarants') {
      return 'All EU-27 member states'
    }
    if (filterName === 'Reporter') {
      return 'All EU-27 member states'
    } else {
      return 'value'
    }
  }

  // Onchange event for multiselect and multiselect hierarchy
  public onSelectionChanged({ component }: any): void {
    if (this.filter.name === 'Partners') {
      const selectedData = component.getSelectedRowsData('leavesOnly')
      this.reportStore.updateCombinedPartnerHierarchiesSelection(selectedData)
    }
    if (this.filter.name === 'EU not EU partner hierarchy') {
      const selectedData = component.getSelectedRowsData('leavesOnly')
      this.reportStore.updateEUNotEUPartnerHierarchySelection(selectedData)
    }
    if (this.filter.name === 'Products' && this.filter.reportId === 1) {
      const selectedData = component.getSelectedRowsData('leavesOnly')
      this.reportStore.updateComextProductSelection(selectedData)
    }
    if (this.filter.name === 'Products' && this.filter.reportId === 2) {
      const selectedData = component.getSelectedRowsData('leavesOnly')
      this.reportStore.updateComtradeProductSelection(selectedData)
    }
    if (this.filter.name === 'Declarants') {
      const selectedData = component.getSelectedRowsData('leavesOnly')
      this.reportStore.updateDimensionsCOMEXTDeclarantSelection(selectedData)
    }
    if (this.filter.name === 'Reporter') {
      const selectedData = component.getSelectedRowsData('leavesOnly')
      this.reportStore.updateDimensionsReporterSelection(selectedData)
    }
  }

  // Onchange event for radiobutton
  public changeRadioSelection(e: Record<string, any>): void {
    const activeFilter = new ActiveFilterModel({
      value: e.value,
      table: this.filter.table,
      column: this.filter.values[0].columnName, // Radio buttons all have the same columnname
      operator: this.filter.operation,
      type: this.filter.filtervisual,
      reportId: this.filter.reportId,
    })
    this.reportStore.updateActiveFilterForRadioButton(activeFilter)
  }
}
