import { ApiUrl } from '../util/api.url'
import FilterResponseModel from '../model/FilterResponseModel'
import { DefaultFilterResponseModel } from '../model/DefaultFilterResponseModel'
import { BaseService } from './base.service'

export class FiltertService {
  private static baseUrl = ApiUrl.webapiurl() + '/Filter/'

  static async GetFilters(reportName: string): Promise<FilterResponseModel[]> {
    const params = {
      reportName: reportName,
    }
    const response = await BaseService.getApiCallWithParam(this.baseUrl + 'GetFilters', params)
    return response.data
  }

  static async GetDefaultFilters(): Promise<DefaultFilterResponseModel[]> {
    const response = await BaseService.getApiCall(this.baseUrl + 'GetDefaultFilters')
    return response.data
  }
}
