import { Guid } from 'guid-typescript'
import _ from 'lodash'
import { FilterValueModel } from './FilterValueModel'

export interface IGroupedFilterModel {
  id: string
}

type ValueOrFactory<T> = T | (() => T)
type PartialValueOrFactory<T> = {
  [P in keyof T]?: ValueOrFactory<T[P]>
}

export class GroupedFilterModel implements IGroupedFilterModel {
  private _column: ValueOrFactory<string>
  private _table: ValueOrFactory<string>
  private _operator: ValueOrFactory<string>
  private _values: ValueOrFactory<FilterValueModel[]>
  private _reportId: ValueOrFactory<number>

  get id(): string {
    return Guid.create().toString()
  }

  get column(): string {
    return this.getValue(this._column)
  }

  get table(): string {
    return this.getValue(this._table)
  }

  get operator(): string {
    return this.getValue(this._operator)
  }

  get values(): FilterValueModel[] {
    return this.getValue(this._values)
  }

  get reportId(): number {
    return this.getValue(this._reportId)
  }



  constructor(init?: PartialValueOrFactory<GroupedFilterModel>) {
    ;(Object as any).assign(
      this,
      _.mapKeys(init, (_value: any, key: string) => '_' + key)
    )
  }

  private getValue<T>(value: ValueOrFactory<T>): T {
    if (value instanceof Function) {
      return value()
    }

    return value
  }
}
