import { Guid } from 'guid-typescript'
import _ from 'lodash'

type ValueOrFactory<T> = T | (() => T)
type PartialValueOrFactory<T> = {
  [P in keyof T]?: ValueOrFactory<T[P]>
}

export class FilterValueModel {
  private _value: ValueOrFactory<string>
  private _id: ValueOrFactory<number>

  get value(): string {
    return this.getValue(this._value)
  }

  get id(): number {
    return this.getValue(this._id)
  }

  constructor(init?: PartialValueOrFactory<FilterValueModel>) {
    ;(Object as any).assign(
      this,
      _.mapKeys(init, (_value: any, key: string) => '_' + key)
    )
  }

  private getValue<T>(value: ValueOrFactory<T>): T {
    if (value instanceof Function) {
      return value()
    }

    return value
  }
}
